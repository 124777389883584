@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    th {
        @apply border-b-2 border-b-gray-800 border border-gray-400 min-w-[158px] ;
    }

    td {
        @apply border border-gray-400 ;
    }

    td::after, td::before{
        @apply content-none absolute w-full
    }
    td::before{
        @apply border-2 border-slate-800 left-1
    }
    td::after{
        @apply border-2 border-slate-800 right-1
    }

    td:nth-child(1), th:nth-child(1){
        @apply border-r-2 border-r-gray-800 sticky left-0 z-10 bg-slate-200;
    }
    
}